@charset "utf-8";

/* =====================================================
フッター
===================================================== */

#footer{
	background: $bgColor;
	@include props(pt,40);
	@include props(pb,40);
	@include mq(md){
		@include props(pt,80,750);
		@include props(pb,40,750);
	}

	.footerInner{
		.pageNavArea{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #707070;
			@include props(pb,30);

			@include mq(md){
				flex-wrap: wrap;
				@include props(pb,40,750);
			}

			.logo{
				@include props(w,248);

				@include mq(md){
					@include props(w,335,750);
				}
			}

			.linkArea{
				ul{
					display: flex;
					li{
						@include props(ml,20);
						@include props(mr,20);
						a{
							@include fz_vw(14);
						}
					}
				}

				@include mq(md){
					@include props(mt,120,750);
					ul{
						flex-wrap: wrap;
						li{
							margin-left: 0;
							margin-right: 0;
							@include props(mb,24,750);
							width: 100%;
							text-align: center;

							a{
								@include fz_vw(28);
							}
						}
					}
				}
			}
		}

		.infoArea{
			display: flex;
			justify-content: space-between;
			@include props(pt,40);
			@include mq(md){
				flex-wrap: wrap;
				@include props(pt,40,750);
			}

			.address{
				p{
					@include fz_vw(14);
					@include mq(md){
						@include fz_vw(24);
					}
				}
				@include mq(md){
					width: 100%;
				}
			}

			.sns{
				@include mq(md){
					@include props(mt,80,750);
					width: 100%;
				}
				ul{
					@include props(mb,18);
					@include mq(md){
						display: flex;
						justify-content: center;
						width: 100%;
						@include props(mb,28,750);
					}
					li{
						@include mq(md){
							@include props(ml,30,750);
							@include props(mr,30,750);
						}
						a{
							@include fz_vw(14);
							@include mq(md){
								@include fz_vw(24);
							}
						}
						&:nth-child(1){
							@include props(mb,12);
							@include mq(md){
								margin-bottom: 0;
							}
							a{
								&:before{
									content: "";
									background: url(/images/common/line_icon.svg) center center no-repeat;
									background-size: contain;
									width: 1.71em;
									height: 1.57em;
									display: inline-block;
									margin-right: 0.625em;
									vertical-align: text-top;
								}
							}
						}
						&:nth-child(2){
							a{
								&:before{
									content: "";
									background: url(/images/common/instagram_icon.svg) center center no-repeat;
									background-size: contain;
									width: 1.71em;
									height: 1.71em;
									display: inline-block;
									margin-right: 0.625em;
									vertical-align: text-top;
								}
							}
						}
					}
				}
				small{
					color: $basicColor;
					@include fz_vw(14);
					@include mq(md){
						@include fz_vw(24);
						display: block;
						text-align: center;
					}
				}
			}
		}
	}
}