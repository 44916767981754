@charset "utf-8";
/* ==============================================
お問い合わせ
============================================== */

#contactTop{
	.contactForm{
		@include props(mb,200);

		@include mq(md){
			@include props(mb,200,750);
		}

		.contactFormField{
			.split{
				width: 87.48%;
				margin-right: auto;

				@include mq(md){
					width: 100%;
				}
				dl{
					display: flex;
					flex-wrap: wrap;
					dt{
						flex-basis: 28.87%;
						@include fz_vw(18);
						&:not(:last-of-type){
							padding-top: .8em;
							@include props(mb,40);
						}

						span{
							@include fz_vw(10);
							color: #ff0000;
							vertical-align: text-top;
						}

						@include mq(md){
							flex-basis: 100%;
							@include fz_vw(28);
							@include props(mb,20,750);

							&:not(:last-of-type){
								@include props(mb,20,750);
							}

							span{
								@include fz_vw(20);
							}
						}
					}

					dd{
						flex-basis: 71.13%;
						@include fz_vw(18);

						@include mq(md){
							flex-basis: 100%;
							@include fz_vw(28);

							a{
								@include fz_vw(28);
							}
							&:not(:last-of-type){
								@include props(mb,60,750);
							}
	
							.errorTxt,.attention{
								@include fz_vw(24);
								@include props(mt,12,750);
							}
						}

						a{
							@include fz_vw(18);
							color: $basicColor;
						}
						&:not(:last-of-type){
							@include props(mb,40);
						}

						.errorTxt,.attention{
							color: #ff0000;
							@include fz_vw(14);
							@include props(mt,10);
						}

						.error{
							background: #ffe3e3;
						}

						input{
							&[type=text],&[type=tel],&[type=email]{
								width: 100%;
								padding: .8em;
								border-radius: 10px;
								@include fz_vw(18);
								outline: none;
								border: 1px solid $basicColor;

								@include mq(md){
									@include props(pt,15,750);
									@include props(pb,15,750);
									@include props(pl,20,750);
									@include props(pr,20,750);
									@include fz_vw(28);
									@include props(br,10,750);
								}
							}

							&[type=radio]{
								appearance: none;
								position: relative;
								margin-right: .55em;

								&:before{
									content: '';
									display: block;
									border-radius: 50%;
									border: 1px solid $basicColor;
									width: 16px;
									height: 16px;

								}
								&:after{
									content: '';
									display: block;
									position: absolute;
									left: 3px;
									top: 3px;
									border-radius: 50%;
									width: 12px;
									height: 12px;
									background-color: $basicColor;
									transform: scale(0);
									transition: all .3s;
								}

								&:checked{
									&:after{
										transform: scale(1);
									}
								}
							}

							&[type=checkbox]{
								appearance: none;
								position: relative;
								margin-right: .55em;

								&:before{
									content: '';
									display: block;
									border-radius: 3px; 
									border: 1px solid $basicColor;
									width: 16px;
									height: 16px;
								}
								&:after{
									content: '';
									display: block;
									position: absolute;
									left: 3px;
									top: 3px;
									border-radius: 3px;
									width: 12px;
									height: 12px;
									background-color: $basicColor;
									transform: scale(0);
									transition: all .3s;
								}

								&:checked{
									&:after{
										transform: scale(1);
									}
								}
							}
						}

						.radioArea{
							display: flex;
							align-items: center;
							flex-wrap: wrap;
							label{
								display: flex;
								align-items: center;
								@include props(mr,40);

								@include mq(md){
									width: 100%;
									@include props(mr,0);
									@include props(mb,12,750);
								}
							}
						}

						.privacyPolicy{
							@include mq(md){
								@include props(pl,20,750);
							}
							.privacyPolicyTxt{
								@include props(mb,40);

								a{
									text-decoration: underline;
								}
	
								@include mq(md){
									@include props(mb,20,750);
								}
							}
						}

						.checkboxArea{
							label{
								display: flex;
								align-items: center;
							}
						}
						textarea{
							resize: vertical;
							width: 100%;
							min-height: 300px;
							border-radius: 10px;
							@include fz_vw(18);
							outline: none;
							border: 1px solid $basicColor;
							padding: .8em;

							@include mq(md){
								@include fz_vw(28);
								@include props(pt,15,750);
								@include props(pb,15,750);
								@include props(pl,20,750);
								@include props(pr,20,750);
								@include props(br,10,750);
							}
						}
					}
				}
			}

			.contactConfirmBtn{
				display: flex;
				justify-content: center;
				@include props(mt,120);
				@include mq(md){
					justify-content: space-between;
					@include props(mt,120,750);
				}

				.contactConfirmBtnBack{
					@include props(w,300);
					@include props(ml,50);
					@include props(mr,50);

					@include mq(md){
					  @include props(w,300,750);
					  @include props(ml,0);
					  @include props(mr,0);
					}
					a{
					  display: block;
					  width: 100%;
					  height: 100%;
					  @include props(p,14);
					  @include fz_vw(16);
					  color: $basicColor;
					  border: 1px solid $keyColor;
					  text-align: center;
					  border-radius: 100px;
				  
					  @include mq(md){
						@include props(p,16,750);
						@include fz_vw(28);
					  }

					  &:before{
						content: "";
						background: url(/images/common/arrow_back.svg) center center no-repeat;
						background-size: contain;
						width: 0.75em;
						height: 0.75em;
						margin-right: 0.625em;
						display: inline-block;
					  }
					}
				}


				.contactConfirmBtnSubmit{
					@include props(w,300);
					@include props(ml,50);
					@include props(mr,50);
				  
					@include mq(md){
					  @include props(w,300,750);
					  @include props(ml,0);
					  @include props(mr,0);
					}
					button{
					  display: block;
					  width: 100%;
					  height: 100%;
					  @include props(p,14);
					  @include fz_vw(16);
					  color: $basicWhiteColor;
					  background: $keyColor;
					  text-align: center;
					  border-radius: 100px;
					  transition: all .3s;
				  
					  @include mq(md){
						@include props(p,16,750);
						@include fz_vw(28);
					  }
				  
					  &:hover{
						cursor: pointer;
						opacity: .6;
					  }
				  
						&:after{
						  content: "";
						  background: url(/images/common/arrow_white_icon.svg) center center no-repeat;
						  background-size: contain;
						  width: 0.75em;
						  height: 0.75em;
						  margin-left: 0.625em;
						  display: inline-block;
						}
					}
				}
			}
		}

		.btn01{
			margin-left: auto;
			margin-right: auto;
		}
	}
}