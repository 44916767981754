@charset "utf-8";
/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.btn01{
  @include props(w,300);

  @include mq(md){
    @include props(w,350,750);
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    @include props(p,14);
    @include fz_vw(16);
    color: $basicWhiteColor;
    background: $keyColor;
    text-align: center;
    border-radius: 100px;

    @include mq(md){
      @include props(p,16,750);
      @include fz_vw(28);
    }

    &.arrow{
      span{
        &:after{
          content: "";
          background: url(/images/common/arrow_white_icon.svg) center center no-repeat;
          background-size: contain;
          width: 0.75em;
          height: 0.75em;
          margin-left: 0.625em;
          display: inline-block;
        }
      }
    }

    &.external{
      span{
        &:after{
          content: "";
          background: url(/images/common/external_white_icon.svg) center center no-repeat;
          background-size: contain;
          width: 0.75em;
          height: 0.75em;
          margin-left: 0.625em;
          display: inline-block;
          vertical-align: text-top;
        }
      }
    }
  }
}

.sendBtn{
  @include props(w,300);
  @include props(mt,120);
  margin-left: auto;
  margin-right: auto;

  @include mq(md){
    @include props(w,350,750);
  }
  button{
    display: block;
    width: 100%;
    height: 100%;
    @include props(p,14);
    @include fz_vw(16);
    color: $basicWhiteColor;
    background: $keyColor;
    text-align: center;
    border-radius: 100px;
    transition: all .3s;

    @include mq(md){
      @include props(p,16,750);
      @include fz_vw(28);
    }

    &:hover{
      cursor: pointer;
      opacity: .6;
    }

    &.arrow{
      &:after{
        content: "";
        background: url(/images/common/arrow_white_icon.svg) center center no-repeat;
        background-size: contain;
        width: 0.75em;
        height: 0.75em;
        margin-left: 0.625em;
        display: inline-block;
      }
    }
  }
}