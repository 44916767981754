@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;

  &.fixedBody {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.maxContainer {
  max-width: 1680px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.container {
  max-width: 1520px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.minContainer {
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}


a {
  color: #555;
}
/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  @include props(pt, 40);
  @include props(pb, 20);
  clear: both;

  @include mq(md) {
    @include props(pt, 30, 750);
    @include props(pb, 50, 750);
  }
  ol {
    @include clearfix;
    position: relative;
    width: 100%;
    word-break: break-all;
    text-align: left;
    line-height: 1;
    color: #acabab;
    margin: 0 auto;
    @include mq(md) {
      max-width: initial;
    }
    &>li {
      display: inline;
      @include fz_vw(18);
      font-weight: 500;
      line-height: 1.22;
      letter-spacing: 0.9px;
      color: #666;
  
      @include mq(md) {
        @include fz_vw(24);
        letter-spacing: 0.5px;
      }

      a {
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: 0.9px;
        color: #666;

        @include mq(md) {
          @include fz_vw(24);
          letter-spacing: 0.5px;
        }
      }
      &:first-child {
        a {
          text-decoration: none;
        }
      }
      &+li::before {
        @include fz_vw(16);
        @include props(pr, 10);
        @include props(pl, 8);
        color: #444444;
        content: "＞";

        @include mq(md) {
          @include fz_vw(20);
          @include props(pr, 10, 750);
          @include props(pl, 10, 750);
        }
      }
    }
  }
}

.columnNav{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include props(mb,200);

  @include mq(md){
    @include props(mb,200,750);
  }
  
  li{
    width: calc(100% / 3);
    position: relative;

    @include mq(md){
      width: 100%;
    }

    a{
      position: relative;
      display: block;

      span{
        position: absolute;
        width: 100%;
        text-align: center;
        display: block;
        @include fz_vw(32);
        top: 50%;
        left: 0;
        transform: translate(0,-50%);
        color: $basicWhiteColor;

        @include mq(md){
          @include fz_vw(40);
        }
      }

      img{
        width: 100%;
      }
    }
  }
}

.pageTopBox{
  text-align: right;
  .pageTop{
    display: inline-block;
    position: relative;
    @include fz_vw(18);

    @include mq(md){
      @include fz_vw(28);
    }
    &:hover{
      cursor: pointer;
      &:before{
        top: -1.5em;
      }
    }
    &:before{
      content: "";
      background: url(/images/common/top_arrow_icon.svg) center center no-repeat;
      background-size: contain;
      width: 0.86em;
      height: 1.59em;
      position: absolute;
      top: -1.2em;
      left: 2em;
      transition: top .3s;
    }
  }
}