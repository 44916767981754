@charset "utf-8";
/* ==============================================
新着記事
============================================== */

#newsTop{
	.postList{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		@include props(mb,200);
	
		@include mq(md){
			@include props(mb,200,750);
		}

		&:after{
			content: "";
			display: block;
			width: 31.03%;

			@include mq(md){
				content: none;
			}
		}
	
		.list{
			width: 31.03%;
			@include props(mb,100);
	
			@include mq(md){
				width: 100%;
	
				&:not(:last-child){
					@include props(mb,80,750);
				}

				&:last-child{
					@include props(mb,0);
				}
			}
	
			.postImg{
				position: relative;
				padding-top: 56.25%;
	
				img{
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					object-fit: cover;
				}
			}
	
			.postDate{
				@include props(mt,40);
	
				.postTtl{
					@include fz_vw(18);
					@include props(mb,20);
				}
	
				time{
					@include fz_vw(18);
				}
	
				@include mq(md){
					@include props(mt,40,750);
					.postTtl{
						@include fz_vw(28);
						@include props(mb,20,750);
					}
	
					time{
						@include fz_vw(28);
					}
				}
			}
		}
	}

	.newsPaging{
		display: flex;
		justify-content: center;
		align-items: center;
		@include props(mb,200);

		@include mq(md){
			@include props(mb,200,750);
		}

		li{
			@include props(pl,20);
			@include props(pr,20);

			@include mq(md){
				@include props(pl,16,750);
				@include props(pr,16,750);
			}

			&.page{
				a{
					display: flex;
					justify-content: center;
					align-items: center;
					@include props(w,40);
					@include props(h,40);
					@include fz_vw(18);
					border-radius: 50%;
					border: 1px solid $basicColor;

					@include mq(md){
						@include props(w,60,750);
						@include props(h,60,750);
						@include fz_vw(28);
					}
				}

				&.active{
					a{
						background: $basicColor;
						color: $basicWhiteColor;
					}
				}
			}

			&.pageArrow{
				a{
					@include fz_vw(18);
					@include mq(md){
						@include fz_vw(28);
					}
				}
				&.back{
					padding-left: 0 !important;
					img{
						display: inline-block;
						vertical-align: middle;
						@include props(mr,7);
						@include props(w,9);
						@include props(h,15);

						@include mq(md){
							@include props(mr,12,750);
							@include props(w,10,750);
							@include props(h,17,750);
						}
					}
				}
				&.next{
					padding-right: 0 !important;
					img{
						display: inline-block;
						vertical-align: middle;
						@include props(ml,7);
						@include props(w,9);
						@include props(h,15);

						@include mq(md){
							@include props(ml,12,750);
							@include props(w,10,750);
							@include props(h,17,750);
						}
					}
				}
			}
		}
	}
}

/* ==============================================
新着記事詳細
============================================== */

#newsPostTop{
	.postSingleBox{
		@include props(mt,270);
		@include props(mb,200);

		@include mq(md){
			@include props(mt,375,750);
			@include props(mb,200,750);
		}


		.articlePost{
			.postDate{
				@include fz_vw(18);
				@include props(mb,12);
				display: block;

				@include mq(md){
					@include fz_vw(28);
					@include props(mb,10,750);
				}
			}

			.postTit{
				@include fz_vw(36);
				font-weight: bold;

				@include mq(md){
					@include fz_vw(40);
				}
			}

			& > .thumbnail{
				width: 100%;
				.img{
					img{
						width: 100%;
						@include props(mt,60);

						@include mq(md){
							@include props(mt,60,750);
						}
					}
				}
			}

			.articlePostInner{
				@include props(mt,60);
				@include props(mb,120);

				@include mq(md){
					@include props(mt,90,750);
					@include props(mb,120,750);
				}

				h2{
					@include fz_vw(30);
					font-weight: bold;
					text-decoration: underline;
					@include props(mb,40);
					@include props(mt,80);

					@include mq(md){
						@include fz_vw(36);
						@include props(mb,40,750);
						@include props(mt,90,750);
					}
				}

				h3{
					@include fz_vw(30);
					font-weight: bold;
					@include props(mb,40);
					@include props(mt,80);

					@include mq(md){
						@include fz_vw(36);
						@include props(mb,40,750);
						@include props(mt,80,750);
					}
				}

				p{
					@include fz_vw(18);
					line-height: 2;

					@include mq(md){
						@include fz_vw(28);
					}

					a{
						@include fz_vw(18);
						line-height: 2;
						text-decoration: underline;
						color: #0000ff;
	
						@include mq(md){
							@include fz_vw(28);
						}
					}
				}

				img{
					@include props(mt,60);
					@include props(mb,60);

					@include mq(md){
						@include props(mt,60,750);
						@include props(mb,60,750);
					}
				}
			}

			.pageLink{
				display: flex;
				justify-content: center;

				li{
					@include props(pl,60);
					@include props(pr,60);

					@include mq(md){
						&:first-child{
							@include props(pl,0,750);
							@include props(pr,30,750);
						}
						&:nth-child(2){
							@include props(pl,30,750);
							@include props(pr,30,750);
						}
						&:last-child{
							@include props(pl,30,750);
							@include props(pr,0,750);
						}

					}

					a{
						display: flex;
						@include fz_vw(18);
						
						.arrowBack{
							@include props(mr,16);
							@include props(h,17);
							@include props(w,9);
							margin-top: auto;
							margin-bottom: auto;
							
						}

						.arrowNext{
							@include props(ml,16);
							@include props(h,17);
							@include props(w,9);
							margin-top: auto;
							margin-bottom: auto;
						}

						.backArchive{
							@include props(mr,16);
							@include props(h,16);
							@include props(w,19);
							margin-top: auto;
							margin-bottom: auto;
						}

						@include mq(md){
							@include fz_vw(28);

							.arrowBack{
								@include props(mr,16,750);
								@include props(h,20,750);
								@include props(w,10,750);
							}
	
							.arrowNext{
								@include props(ml,16,750);
								@include props(h,20,750);
								@include props(w,10,750);
							}

							.backArchive{
								@include props(mr,16,750);
								@include props(h,23,750);
								@include props(w,27,750);
							}
						}
					}
				}
			}
		}
	}
}