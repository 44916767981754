@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

// ローディング画面
#loading {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #ffffff;
	opacity: 1;
	z-index: 100000;
}

.external.black{
	&:after{
		content: "";
		background: url(/images/common/external_icon.svg) center center no-repeat;
		background-size: contain;
		width: 0.75em;
		height: 0.75em;
		margin-left: 0.625em;
		display: inline-block;
		vertical-align: text-top;
	}
}

span.external.white{
	&:after{
		content: "";
		background: url(/images/common/external_white_icon.svg) center center no-repeat;
		background-size: contain;
		width: 0.75em;
		height: 0.75em;
		margin-left: 0.625em;
		display: inline-block;
		vertical-align: text-top;
	}
}

main:not(#indexTop){
	.lowerLayerMainVisual{
		.mainVisual{
			position: relative;
			max-height: 700px;
			@include props(mb,120);

			@include mq(md){
				@include props(mb,200,750);
			}

			img{
				width: 100%;
				max-height: 700px;
				object-fit: cover;
			}

			.mainTtl{
				position: absolute;
				width: 100%;
				max-width: 1406px;
				padding: 0 20px;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				@include fz_vw(64);

				@include mq(md){
					width: 100%;
					@include props(pl,40,750);
					@include props(pr,40,750);
					@include fz_vw(56);
					top: inherit;
					@include props(b,160,750);
					left: 0;
					transform: none;
				}
				span{
					@include fz_vw(32);
					color: $keyColor;
					display: block;
				}
				
			}
		}

		.pageMainDesc{
			@include fz_vw(16);
			line-height: 2;
			@include props(mb,120);

			@include mq(md){
				@include fz_vw(28);
				@include props(mb,200,750);
			}
		}
	}

	//お問い合わせとプライバシーポリシー
	.noImgTtlArea{
		@include props(mt,300);
		@include props(mb,100);

		@include mq(md){
			@include props(mt,415,750);
			@include props(mb,120,750);
		}
		h1{
			@include fz_vw(64);
			@include props(mb,200);

			@include mq(md){
				@include fz_vw(56);
				@include props(mb,200,750);
			}
			span{
				@include fz_vw(32);
				color: $keyColor;
				display: block;
			}
		}

		.pageDesc{
			@include fz_vw(18);
			line-height: 2;

			@include mq(md){
				@include fz_vw(28);
			}
		}
	}
}