@charset "utf-8";

/* 見出し装飾--------------------------- */
.secTtl01{
	@include props(mb,80);

	@include mq(md){
		@include props(mb,80,750);
	}
	span{
		font-family: $Sen;
		@include fz_vw(100);
		line-height: 1;
		margin-bottom: .1em;

		@include mq(md){
			@include fz_vw(70);
		}
	}
	h3{
		color: $keyColor;
		@include fz_vw(32);
		position: relative;

		@include mq(md){
			@include fz_vw(36);
		}

		&:before{
			content: "";
			position: absolute;
			@include props(l,-10);
			top: 0;
			bottom: 0%;
			margin: auto 0;
			width: 100vw;
			background: $keyColor;
			transform-origin: top left;
			height: 1px;
			transform: scaleX(-1);

			@include mq(md){
				content: none;
			}
		}
	}
}