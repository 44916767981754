@charset "utf-8";
/* ==============================================
店舗紹介
============================================== */

#storeTop{
	.storeList{
		@include props(mb,153);
		@include mq(md){
			@include props(mb,160,750);
		}
		.wp-block-gallery{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
	
			&:after{
				content: "";
				display: block;
				width: 31.03%;
	
				@include mq(md){
					content: none;
				}
			}
	
			.wp-block-image{
				width: 31.03%;
				@include props(mb,47);
	
				@include mq(md){
					@include props(w,315,750);
					@include props(mb,40,750);
				}
			}
		}
	}
}