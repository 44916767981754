@charset "utf-8";
/* ==============================================
入居者様紹介
============================================== */

#tenantTop{
	.tenantList{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include props(mb,170);

		&:after{
			content: "";
			display: block;
			width: 31.03%;

			@include mq(md){
				content: none;
			}
		}

		@include mq(md){
			@include props(mb,300,750);
		}

		.list{
			width: 31.03%;
			position: relative;
			@include props(mb,130);

			@include mq(md){
				width: 100%;
				&:not(:last-child){
					@include props(mb,180,750);
				}
			}
			
			img{
				width: 100%;
			}

			.tenantInfoArea{
				box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.4);
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%,50%);
				background: $basicWhiteColor;
				width: 90%;
				max-width: 440px;
				@include props(pt,30);
				@include props(pb,30);
				@include props(pl,20);
				@include props(pr,20);

				@include mq(md){
					@include props(w,590,750);
					@include props(pt,30,750);
					@include props(pb,30,750);
					@include props(pl,40,750);
					@include props(pr,40,750);
				}

				.tenantName{
					@include fz_vw(24);
					@include props(mb,15);

					@include mq(md){
						@include fz_vw(40);
						@include props(mb,40,750);
					}
				}

				.tenantLinkList{
					display: flex;
					justify-content: flex-start;
					li{
						a{
							@include fz_vw(14);

							@include mq(md){
								@include fz_vw(28);
							}

							&.insta{
								&:before{
									content: "";
									background: url(/images/common/instagram_icon.svg) center center no-repeat;
									background-size: contain;
									width: 1.5em;
									height: 1.5em;
									display: inline-block;
									vertical-align: sub;
									margin-right: 0.375em;
								}
							}
							&.reserve{
								&:before{
									content: "";
									background: url(/images/common/reserve_icon.svg) center center no-repeat;
									background-size: contain;
									width: 1.375em;
									height: 1.437em;
									display: inline-block;
									vertical-align: sub;
									margin-right: 0.375em;
								}
							}
						}

						&:nth-child(2){
							@include props(ml,50);
							position: relative;
							&:before{
								content: "";
								position: absolute;
								top: 0;
								bottom: 0;
								@include props(l,-25);
								width: 1px;
								background: $basicColor;
								transform: rotate(15deg);
							}

							@include mq(md){
								@include props(ml,54,750);
								&:before{
									@include props(l,-27,750);
								}
							}
						}
					}
				}
			}
		}
	}
}