@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#indexTop{
	overflow: hidden;
	.secDesc{
		@include fz_vw(16);
		line-height: 2;

		@include mq(md){
			@include fz_vw(28);
		}
	}
	.mainVisual{
		position: relative;
		padding-top: 49.47%;
		width: 100%;

		@include mq(md){
			padding-top: 133.333%;
		}

		.mainTtl{
			position: absolute;
			width: 36.45vw;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 2;

			@include mq(md){
				@include props(w,500,750);
			}
		}

		.mainVisualSlide{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			transition: opacity .3s;

			img{
				width: 100%;
				height: 100%;
			}

			&.slick-initialized{
				opacity: 1;
			}
		}
	}

	.aboutLayout{
		@include props(pt,200);
		@include props(mb,200);

		@include mq(md){
			@include props(pt,200,750);
			@include props(mb,200,750);

			.btn01{
				@include props(mt,80,750);
			}
		}

		.btn01{
			@include props(mt,60);
			margin-left: auto;
		}
	}

	.tenantlayout{
		@include props(mb,200);
		&:before{
			content: "";
			background: $bgColor;
			position: absolute;
			left: 0;
			@include props(t,50);
			width: 76.6666vw;
			height: 94%;
			z-index: -1;

			@include mq(max){
				height: calc(100% - 70px);
			}
		}

		@include mq(md){
			@include props(mb,200,750);

			&:before{
				@include props(t,35,750);
				@include props(w,630,750);
				height: 97.71%;
			}
		}

		.tenantList{
			display: flex;
			flex-wrap: wrap;
			@include props(mb,192);
			@include props(mt,120);

			@include mq(md){
				@include props(mb,180,750);
				@include props(mt,120,750);
				@include props(pl,40,750);
				@include props(pr,40,750);
			}

			.list{
				width: calc(100% / 3);
				position: relative;

				@include mq(md){
					width: 100%;
					&:not(:last-child){
						@include props(mb,180,750);
					}
				}
				
				img{
					width: 100%;
				}

				.tenantInfoArea{
					box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.4);
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%,50%);
					background: $basicWhiteColor;
					width: 90%;
					max-width: 440px;
					@include props(pt,40);
					@include props(pb,40);
					@include props(pl,40);
					@include props(pr,40);

					@include mq(md){
						@include props(w,590,750);
						@include props(pt,30,750);
						@include props(pb,30,750);
						@include props(pl,40,750);
						@include props(pr,40,750);
					}

					.tenantName{
						@include fz_vw(24);
						@include props(mb,15);

						@include mq(md){
							@include fz_vw(40);
							@include props(mb,40,750);
						}
					}

					.tenantLinkList{
						display: flex;
						justify-content: flex-start;
						li{
							a{
								@include fz_vw(16);

								@include mq(md){
									@include fz_vw(28);
								}

								&.insta{
									&:before{
										content: "";
										background: url(/images/common/instagram_icon.svg) center center no-repeat;
										background-size: contain;
										width: 1.5em;
										height: 1.5em;
										display: inline-block;
										vertical-align: sub;
										margin-right: 0.375em;
									}
								}
								&.reserve{
									&:before{
										content: "";
										background: url(/images/common/reserve_icon.svg) center center no-repeat;
										background-size: contain;
										width: 1.375em;
										height: 1.437em;
										display: inline-block;
										vertical-align: sub;
										margin-right: 0.375em;
									}
								}
							}

							&:nth-child(2){
								@include props(ml,60);
								position: relative;
								&:before{
									content: "";
									position: absolute;
									top: 0;
									bottom: 0;
									@include props(l,-30);
									width: 1px;
									background: $basicColor;
									transform: rotate(15deg);
								}

								@include mq(md){
									@include props(ml,54,750);
									&:before{
										@include props(l,-27,750);
									}
								}
							}
						}
					}
				}
			}
		}

		.btn01{
			margin-left: auto;
			margin-right: auto;
		}
	}


	.storeLayout{
		position: relative;
		@include props(mb,200);
		
		&:before{
			content: "";
			background: $bgColor;
			position: absolute;
			right: 0;
			@include props(t,90);
			width: 76.66666vw;
			height: 74.1111%;
			z-index: -1;
		}

		@include mq(md){
			@include props(mb,200,750);

			&:before{
				@include props(t,35,750);
				@include props(w,630,750);
				height: calc(100% - 4.66666vw);
			}
		}

		.storeCon{
			display: flex;
			align-items: flex-start;
			position: relative;

			@include mq(md){
				flex-direction: column-reverse;
				@include props(pb,120,750);
			}

			.img{
				width: 50%;
				.img01{
					width: 85.36%;
				}

				.img02{
					width: 48.78%;
					margin-left: auto;
					@include props(mt,-200);
				}

				@include mq(md){
					@include props(w,670,750);
					position: absolute;
					@include props(b,250,750);
					@include props(l,40,750);
				}
			}

			.txt{
				width: 50%;

				@include mq(md){
					width: 100%;
				}

				.txtInner{
					width: 100%;
					max-width: 683px;
					@include props(pt,40);

					@include mq(md){
						width: 100%;
						@include props(pt,0,750);
					}

					.secTtl02{
						@include props(mb,80);

						@include mq(md){
							@include props(mb,80,750);
						}

						span{
							padding-left: 17.56%;
							font-family: $Sen;
							@include fz_vw(100);
							line-height: 1;
							margin-bottom: .1em;

							@include mq(md){
								padding-left: 0;
								@include fz_vw(70);
							}
						}
						
						h3{
							padding-left: 17.56%;
							color: $keyColor;
							@include fz_vw(32);
							position: relative;

							&:before{
								content: "";
								position: absolute;
								@include props(l,0);
								top: 0;
								bottom: 0;
								margin: auto 0;
								width: 14.64%;
								background: $keyColor;
								height: 1px;
							}

							@include mq(md){
								padding-left: 0;
								@include fz_vw(28);

								&:before{
									content: none;
								}
							}
						}
					}

					.secDesc{
						padding-left: 17.56%;
						@include props(mb,60);

						@include mq(md){
							padding-left: 0;
							@include props(mb,916,750);
						}
					}

					.btn01{
						margin-left: auto;

						@include mq(md){
							margin: 0 auto;
						}
					}
				}
			}
		}
	}


	.newsLayout{
		@include props(mb,200);
		position: relative;

		&:before{
			content: "";
			background: $bgColor;
			position: absolute;
			left: 0;
			@include props(t,50);
			width: 76.66666vw;
			height: 90.82%;
			z-index: -1;
		}

		@include mq(md){
			@include props(mb,200,750);
			&:before{
				@include props(w,630,750);
				@include props(t,35,750);
				height: 97.2%;
			}
		}

		.postList{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			@include props(mb,90);
			@include props(mt,80);

			@include mq(md){
				@include props(mt,120,750);
			}

			&::after{
				content: "";
				display: block;
				width: 31.03%;
				display: block;
			}

			&:after{
				content: "";
				width: 31.03%;
				display: block;

				@include mq(md){
					content: none;
				}
			}

			.list{
				width: 31.03%;

				@include mq(md){
					width: 100%;

					&:not(:last-child){
						@include props(mb,80,750);
					}
				}

				.postImg{
					position: relative;
					padding-top: 56.25%;

					img{
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						object-fit: cover;
					}
				}

				.postDate{
					@include props(mt,40);

					.postTtl{
						@include fz_vw(18);
						@include props(mb,20);
					}

					time{
						@include fz_vw(18);
					}

					@include mq(md){
						@include props(mt,40,750);
						.postTtl{
							@include fz_vw(28);
							@include props(mb,20,750);
						}
	
						time{
							@include fz_vw(28);
						}
					}
				}
			}
		}

		.btn01{
			margin: 0 auto;
		}
	}


	.accessLayout{
		position: relative;
		@include props(mb,200);
		
		&:before{
			content: "";
			background: $bgColor;
			position: absolute;
			right: 0;
			@include props(t,90);
			width: 76.66666vw;
			height: 70.74%;
			z-index: -1;
		}

		@include mq(md){
			@include props(mb,200,750);

			&:before{
				@include props(t,35,750);
				@include props(w,630,750);
				height: 79.52%;
			}
		}

		.accessCon{
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;

			@include mq(md){
				flex-direction: column-reverse;
			}

			.map{
				width: 50%;
				@include props(mt,160);
				
				iframe{
					width: 92.29%;
					height: 425px;
				}

				@include mq(md){
					width: 100%;
					@include props(mt,120,750);

					iframe{
						width: 100%;
						@include props(h,376,750);
					}
				}
			}

			.txt{
				width: 50%;

				@include mq(md){
					width: 100%;
				}

				.txtInner{
					width: 100%;
					max-width: 683px;
					@include props(pt,40);

					@include mq(md){
						max-width: 100%;
						@include props(pt,0,750)
					}

					.secTtl02{
						@include props(mb,80);
						@include mq(md){
							@include props(mb,80,750);
						}
						span{
							padding-left: 17.56%;
							font-family: $Sen;
							@include fz_vw(100);
							line-height: 1;
							margin-bottom: .1em;

							@include mq(md){
								padding-left: 0;
								@include fz_vw(70);
							}
						}
						
						h3{
							padding-left: 17.56%;
							color: $keyColor;
							@include fz_vw(32);
							position: relative;

							&:before{
								content: "";
								position: absolute;
								@include props(l,0);
								top: 0;
								bottom: 0;
								margin: auto 0;
								width: 14.64%;
								background: $keyColor;
								height: 1px;
							}

							@include mq(md){
								padding-left: 0;
								@include fz_vw(32);

								&:before{
									content: none;
								}
							}
						}
					}

					.addressInfo{
						padding-left: 17.56%;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;

						@include mq(md){
							padding-left: 0;
						}
						dt{
							width: 17.76%;
							@include fz_vw(16);

							@include mq(pc){
								&:not(:last-of-type){
									@include props(mb,16);
								}
							}

							@include mq(md){
								@include fz_vw(28);
								width: 100%;
								@include props(mb,12,750);
							}
						}
						dd{
							width: 82.24%;
							@include fz_vw(16);
							&:not(:last-of-type){
								@include props(mb,16);
							}

							@include mq(md){
								padding-left: 1em;
								@include fz_vw(28);
								width: 100%;
								&:not(:last-of-type){
									@include props(mb,54,750);
								}
							}
						}
					}
				}
			}
		}
	}
}
