@charset "utf-8";
/* ==============================================
プライバシー
============================================== */
#privacyTop{
	.privacyListArea{
		@include props(mb,200);
		@include mq(md){
			@include props(mb,200,750);
		}
		.privacyList{
			&:not(:last-child){
				@include props(mb,80);

				@include mq(md){
					@include props(mb,80,750);
				}
			}

			.privacyTtl{
				@include fz_vw(24);
				font-weight: bold;
				@include props(mb,40);

				@include mq(md){
					@include fz_vw(40);
					@include props(mb,40,750);
				}
			}

			.privacyDesc{
				@include fz_vw(18);
				line-height: 2;

				@include mq(md){
					@include fz_vw(28);
				}

				&.endPolicy{
					text-align: right
				}
			}

			.childListArea{
				.childList{
					.childListDesc{
						@include fz_vw(18);
						line-height: 2;
						padding-left: 1em;
						text-indent: -1em;

						@include mq(md){
							@include fz_vw(28);
						}

						span{
							font-weight: bold;
						}
					}
				}
			}

			.grandchildListArea{
				.grandchildList{
					@include fz_vw(18);
					line-height: 2;
					padding-left: 3em;
					text-indent: -2.5em;

					@include mq(md){
						@include fz_vw(28);
					}
					span{
						font-weight: bold;
					}
				}
			}

			.lastListArea{
				text-indent: 0;
				.lastList{
					@include fz_vw(18);
					line-height: 2;
					padding-left: 1em;
					text-indent: -1em;

					@include mq(md){
						@include fz_vw(28);
					}
				}
			}
		}
	}
}