@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  position: fixed;
  z-index: 10;
  width: 100%;
  @include props(t,20);
  left: 0;
  right: 0;
  padding: 0 20px;
  @include mq(md){
    @include props(t,40,750);
    @include props(pl,40,750);
    @include props(pr,40,750);
  }

  .headerInner{
    background: $bgColor;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include props(pl,40);
    @include props(pr,40);
    position: relative;
    @include mq(md){
      @include props(pl,40,750);
      @include props(pr,40,750);
      @include props(btlr,45,750);
      @include props(btrr,45,750);
      @include props(bblr,45,750);
      @include props(bbrr,45,750);
      display: block;
      overflow: hidden;
    }

    .logo{
      @include props(w,214);
      @include props(pt,16);
      @include props(pb,16);

      @include mq(md){
        @include props(w,214,750);
        @include props(pt,16,750);
        @include props(pb,16,750);
      }
    }

    @include mq(md){
      .navBtn{
        position: absolute;
        @include props(t,30,750);
        bottom: 0;
        @include props(r,40,750);
        @include props(w,50,750);
        @include props(h,40,750);
        cursor: pointer;

        span{
          display: inline-block;
          position: absolute;
          right: 0;
          @include props(w,50,750);
          height: 1px;
          background: $basicColor;
          transition: all .5s ease-in-out;
          &:nth-last-of-type(1){
            top: 0;
          }
          &:nth-last-of-type(2){
            top: 49%;
            @include props(w,40,750);
          }
          &:nth-last-of-type(3){
            bottom: 0;
          }
        }

        &.isOpen{
          span{
            &:nth-of-type(1),
            &:nth-of-type(3){
              top: 50%;
              background: $basicColor;
              @include props(w,60,750);
            }
            &:nth-of-type(1){
              transform: rotate(-135deg);
            }
            &:nth-of-type(2){
              opacity: 0;
            }
            &:nth-of-type(3){
              transform: rotate(135deg);
            }
          }
        }
      }
    }

    .globalNavArea{
      @include mq(pc){
        display: block !important;
      }
      ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include mq(pc){
          height: 100%;
        }

        .globalNav{
          @include props(ml,20);
          @include props(mr,20);
          display: flex;
          align-items: center;
          height: 100%;
          a{
            @include fz_vw(16);
          }
          &:not(.globalNavBtn){
            &.current{
              border-bottom: 1px solid $keyColor;
            }
          }
          &.globalNavBtn{
            a{
              border: 1px solid $keyColor;
              display: inline-block;
              @include props(pt,16);
              @include props(pb,16);
              @include props(pl,50);
              @include props(pr,50);
              border-radius: 100px;
            }

            &.lpBtn{
              a{
                svg{
                  width: 0.75em;
                  height: 0.75em;
                }
              }
            }

            &.contactBtn{
              a{
                background: $keyColor;
                color: $basicWhiteColor;
              }
            }
          }
        }
      }
    }
    @include mq(md){
      .globalNavArea{
        width: 100%;
        display: none;
        overflow-y: scroll;
        @include props(h,1000,750);
        max-height: calc(100vh - 45.33vw);
        ul{
          @include props(pt,120,750);
          @include props(pb,80,750);
          width: 100%;
          display: block;
          text-align: center;

          .spNav{
            width: 100%;
            opacity: 0;
            transform: translateY(10px);
            transition: all 1s .8s;
            margin: 0 auto ;
            display: inline-block;
            &:not(:last-child){
              @include props(mb,60,750);
            }
            a{
              @include fz_vw(28);
            }
            &.current{
              border-bottom: none !important;
              a{
                  border-bottom: 1px solid $keyColor;
              }
            }

            &.globalNavBtn{
                @include props(w,400,750);
                a{
                  width: 100%;
                  height: 100%;
                  @include props(pt,16,750);
                  @include props(pb,16,750);
                }
            }
          }
        }
        &.isShow{
          
          .spNav{
            transform: none;
            opacity: 1;
          }
        }
      }
    }
  }
}